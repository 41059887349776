import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { menuRoles } from './core/constants/roles';
import { permissaoRotaGuard } from './core/guards/permissao-rota.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'solicitacoes'
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: [] }
    },
    {
        path: 'nao-autorizado',
        loadChildren: () =>
            import('./pages/sem-autorizacao/sem-autorizacao.module').then(
                (m) => m.SemAutorizacaoModule
            )
    },
    {
        path: 'solicitacoes',
        loadChildren: () =>
            import('./pages/solicitacoes/solicitacoes.module').then((m) => m.SolicitacoesModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.solicitacoes.solicitacoes.visualizar }
    },
    {
        path: 'areaexecutora',
        loadChildren: () =>
            import('./pages/area-executora/area-executora.module').then(
                (m) => m.AreaExecutoraModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.parametrizacoes.areaExecutora.visualizar }
    },
    {
        path: 'categoriaprojeto',
        loadChildren: () =>
            import('./pages/categoria-projeto/categoria-projeto.module').then(
                (m) => m.CategoriaProjetoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.parametrizacoes.categoriaDeProjeto.visualizar }
    },
    {
        path: 'taxasparametrizaveis',
        loadChildren: () =>
            import('./pages/taxas-parametrizaveis/taxas-parametrizaveis.module').then(
                (m) => m.TaxasParametrizaveisModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.parametrizacoes.taxasParametrizaveis.visualizar }
    },
    {
        path: 'grupo-qualitativo',
        loadChildren: () =>
            import('./pages/grupo-qualitativo/grupo-qualitativo.module').then(
                (m) => m.GrupoQualitativoModule
            ),
        data: { roles: menuRoles.itensMenu.grupoQualitativo.grupos.visualizar }
    },
    {
        path: 'criterio',
        loadChildren: () =>
            import('./pages/criterio/criterio.module').then((m) => m.CriterioModule),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.grupoQualitativo.criterios.visualizar }
    },
    {
        path: 'questionarioqualitativo',
        loadChildren: () =>
            import('./pages/questionario-qualitativo/questionario-qualitativo.module').then(
                (m) => m.QuestionarioQualitativoModule
            ),
        canActivate: [permissaoRotaGuard],
        data: { roles: menuRoles.itensMenu.grupoQualitativo.questionarioQualitativo.visualizar }
    },
    {
        path: 'upload-arquivos',
        loadChildren: () =>
            import('./pages/upload-arquivos/upload-arquivos.module').then(
                (m) => m.UploadArquivosModule
            ),
        data: { roles: menuRoles.itensMenu.template.uploadarquivos.visualizar }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
